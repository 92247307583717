import React from 'react';
import PolkaDots from '../consulting/PolkaDots';
import FooterSection from './FooterSection';
import {ReactComponent as Sin2Io} from '../assets/logo/logo_old_old.svg';
import Socials from '../shared/Socials';

export const Footer: React.FC = () => {

    const sections = ['company', 'culture', 'contact', 'legal']
    const renderedSections = sections.map(section => <FooterSection variant={section}/>)
    return (
        <div id={'footer'} className={'w-100 bg-black pt-5'}>
            <PolkaDots visible={true} color={''}/>
            <div className="container pb-5">
                <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-3 py-5 my-5 border-top border-bottom">
                    <div className="col mb-3">
                        <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                            <Sin2Io className={'synexion-dark gradient-text'}/>
                        </a>
                        <Socials variant={'footer'}/>
                        <div className="col mb-3"/>
                        <small className="text-muted text-">Komplexität beherrschen.
                            <br/>Software made with <br/>❤️ and 🧠 in Germany.
                            <br/>© 2021-{new Date().getFullYear()}
                        </small>
                    </div>
                    {renderedSections}
                </footer>
            </div>
        </div>
    )
}

export default Footer;