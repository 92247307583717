export interface BlogEntry {
    id: string
    urlId: string
    title: string
    author: string
    authorImageURL: string
    date: string
    preview: string
    content: string
    coverImageURL: string
    tags: string[]
    likes: number
}

export interface BlogEntries {
    items: BlogEntry[],
    totalCount: number,
    previous: number,
    next: number,
    resultCount: number
}

export enum SubscriptionState {
    UNKNOWN = 'UNKNOWN',
    LINK_INVALID = 'LINK_INVALID',
    LINK_ALREADY_USED = 'LINK_ALREADY_USED',
    OK = 'OK'
}

export interface SubscriptionActivationResponse {
    message: string,
    status: SubscriptionState
}