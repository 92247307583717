import {createServer} from 'miragejs';
import {BlogEntries, BlogEntry} from '../../models/models';
import {OpenHABEntry, ReactBlogEntry} from './mock_entries';
import {AnyResponse} from 'miragejs/-types';

export default class Mock {

    init() {
        const windowAny = window as any;
        if (windowAny.server) {
            windowAny.server.shutdown();
        }
        if (process.env.REACT_APP_API_URL === 'http://localhost:3005' && process.env.REACT_APP_USE_MIRAGE === 'true') {
            windowAny.server = createServer({
                routes() {
                    this.get('/blog', (_, request): BlogEntries => {
                        const limit = request.queryParams.limit;
                        if (limit && limit === '1') {
                            return {
                                items: [ReactBlogEntry],
                                totalCount: 10,
                                resultCount: 1,
                                previous: 0,
                                next: 1
                            };
                        }
                        const page = request.queryParams.page;
                        switch (page) {
                            case '0':
                                return {
                                    items: [ReactBlogEntry, OpenHABEntry, ReactBlogEntry, OpenHABEntry],
                                    totalCount: 10,
                                    resultCount: 4,
                                    previous: 0,
                                    next: 1
                                };
                            case '1':
                                return {
                                    items: [ReactBlogEntry, OpenHABEntry, ReactBlogEntry, OpenHABEntry, OpenHABEntry, ReactBlogEntry, ReactBlogEntry, OpenHABEntry],
                                    totalCount: 10,
                                    resultCount: 8,
                                    previous: 0,
                                    next: 2
                                };
                            case '2':
                                return {
                                    items: [ReactBlogEntry, OpenHABEntry],
                                    totalCount: 10,
                                    resultCount: 2,
                                    previous: 1,
                                    next: 2
                                };
                            default:
                                return {
                                    items: [ReactBlogEntry, OpenHABEntry, ReactBlogEntry, OpenHABEntry],
                                    totalCount: 10,
                                    resultCount: 4,
                                    previous: 0,
                                    next: 1
                                };
                        }
                    });
                    this.get('/blog/developing-an-openhab-binding', (): BlogEntry => {
                        return OpenHABEntry;
                    });
                    this.get('/blog/how-to-code-a-blog-in-react', (): BlogEntry => {
                        return ReactBlogEntry;
                    });

                    this.post('/blog/:id/like', (schema, request): AnyResponse => {
                        return {message: 'Liked that shit dude', urlId: request.params.id, liked: Math.random() > 0.5};
                    });
                }
            });
        }
    }
}