import React, {ChangeEvent, useEffect, useState} from 'react';
import './Contact.scss';
import SectionHeading from '../consulting/SectionHeading';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {ReCAPTCHA} from 'react-google-recaptcha';
import Hint from '../shared/Hint';

interface ContactProps {
    variant: string;
    close?: () => void;
}

interface FormState {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    category: string;
    message: string;
    isValid: boolean;
}

const initialFormState: FormState = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    category: '',
    message: '',
    isValid: false,
};

const Contact: React.FC<ContactProps> = ({variant, close}) => {
    const [formState, setFormState] = useState<FormState>(initialFormState);

    useEffect(() => {
        const validateForm = () => {
            const {firstName, lastName, email, category, message} = formState;
            const isValid =
                firstName.length > 0 &&
                lastName.length > 0 &&
                email.length > 0 &&
                category.length > 0 &&
                message.length > 0;

            setFormState((prevState) => ({
                ...prevState,
                isValid,
            }));
        };

        validateForm();
    }, [formState.firstName, formState.lastName, formState.email, formState.category, formState.message]);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="contact ps-1 pb-lg-0 pb-5">
            <Hint variant="getInTouch"/>
            <ReCAPTCHA
                sitekey="6Le0izsfAAAAACf4uaNaW8TyXzxEn2g5VQu_4IPO"
                size="normal"
                onChange={() => console.log('Recaptcha changed')}
            />
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <SectionHeading heading="Kontakt" subHeading="Ihre Anfrage"/>
                </div>
            </div>
            <form target="_blank" action="https://formsubmit.co/sin2@sin2.io" method="POST">
                <div className="form-group ms-auto me-auto" style={{maxWidth: '800px'}}>
                    <div className="row pb-3">
                        <div className="col-12 col-lg-6">
                            <label>
                                Vorname
                            </label>
                            <input
                                onChange={handleChange}
                                type="text"
                                name="firstName"
                                value={formState.firstName}
                                className={`form-control form-control-${variant}`}
                                required
                            />
                        </div>
                        <div className="mt-3 mt-lg-0 col-12 col-lg-6">
                            <label>
                                Nachname
                            </label>
                            <input
                                onChange={handleChange}
                                type="text"
                                name="lastName"
                                value={formState.lastName}
                                className={`form-control form-control-${variant}`}
                                required
                            />
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12 col-lg-6">
                            <label>
                                E-Mail
                            </label>
                            <input
                                onChange={handleChange}
                                type="email"
                                name="email"
                                value={formState.email}
                                className={`form-control form-control-${variant}`}
                                required
                            />
                        </div>
                        <div className="mt-3 mt-lg-0 col-12 col-lg-6">
                            <label className={'d-flex justify-content-between'}>Firma<small
                                className="text-muted ms-auto small"><small>Optional</small></small></label>
                            <input
                                onChange={handleChange}
                                type="text"
                                name="company"
                                value={formState.company}
                                className={`form-control form-control-${variant}`}
                            />
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12">
                            <label>
                                Was beschreibt Ihr Anliegen am Besten?
                            </label>
                            <select
                                onChange={handleChange}
                                name="category"
                                value={formState.category}
                                className={`form-select form-control form-control-${variant}`}
                                required
                            >
                                <option>Backend-Entwicklung mit Spring / Java</option>
                                <option>Frontend-Entwicklung: Websites</option>
                                <option>Mobile App Entwicklung</option>
                                <option>Sonstiges</option>
                            </select>
                        </div>
                    </div>
                </div>
                <input type="hidden" name="_next" value="https://sin2.io"/>
                <input type="hidden" name="_captcha" value="false"/>
                <div className="form-group ms-auto me-auto" style={{maxWidth: '800px'}}>
                    <label>
                        Ihre Nachricht
                    </label>
                    <textarea
                        onChange={handleChange}
                        className={`form-control form-control-${variant}`}
                        name="message"
                        rows={8}
                        value={formState.message}
                        required
                    />
                </div>
                <div className="mt-5 text-center">
                    <button
                        disabled={!formState.isValid}
                        type="submit"
                        className={`button-main button-main-light ${formState.isValid ? '' : ' disabled'}`}
                    >
                        ANFRAGE STELLEN
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Contact;
