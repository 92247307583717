import React from 'react';
import './Hero.scss';
import ContentHolder from '../../shared/ContentHolder';
import PolkaDots from '../../consulting/PolkaDots';
import {subscribeToNewsletter} from '../api/client';
import FadeIn from 'react-fade-in';


const Hero: React.FC = () => {

    const [email, setEmail] = React.useState('');
    const [isvalidEmail, setIsValidEmail] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [error, setError] = React.useState('');

    function setMessageOrError(res: any) {
        if (res.message) {
            setMessage(res.message);
            setError('');
        } else if (res.error) {
            setError(res.error);
            setMessage('');
        }
    }

    function validateEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <div id={'hero'} className={'blog-hero position-relative'}>
            <div className={'blog-hero-container'}>
                <ContentHolder backgroundColor={'#FFF'}>
                    <div className={'pic-container'}>
                        <div className={'small-sinthu'}/>
                    </div>
                    <div className={'text-center'}>
                        Hey there! My name is Sinthu and I like building stuff. Welcome to my blog! <br/>
                        I hope you enjoy your stay. I like to post about interesting stuff that I find worthwhile
                        sharing, most of it has to do with coding and nerdy stuff. During the day I'm a fullstack
                        developer in the automotive industry, at night I am a game developer.
                    </div>
                    <div className={'py-4'}>
                        <PolkaDots color={'-red'} visible={true}/>
                    </div>
                    <h3 className={'text-center'}>Join my newsletter to stay up to date.</h3>

                    <div className={'mt-5 container-fluid p-0'}>
                        {error &&
                            <FadeIn>
                                <div className={'alert alert-danger'}>
                                    {error}
                                </div>
                            </FadeIn>
                        }
                        {message &&
                            <FadeIn>
                                <div className={'alert alert-success'}>
                                    {message}
                                </div>
                            </FadeIn>
                        }

                        <div className={'row newsletter-form p-3 mx-2'}>
                            <div className={'col-lg-9 col-md-12 my-lg-0 my-3'}>
                                <input type='email' className={'p-2 w-100 border-0 m-0'}
                                       placeholder={'📧 Your E-Mail'}
                                       onChange={(e) => {
                                           const input = e.target.value;
                                           setIsValidEmail(validateEmail(input));
                                           setEmail(input);
                                       }}/>
                            </div>
                            <div className={'col-lg-3 col-md-12'}>
                                <button disabled={!isvalidEmail} id={'subscribe-button'}
                                        className={'btn btn-primary w-100 p-2 mb-lg-0 mb-3'}
                                        onClick={() => subscribeToNewsletter(email).then(res => setMessageOrError(res))}>
                                    <span className={'mdi mdi-email me-2'}/>Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </ContentHolder>
            </div>
        </div>
    );
}
export default Hero;