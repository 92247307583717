import React from 'react';
import './Consulting.scss';
import List from './List';
import {Cycler} from './Cycler';
import {FloatingIcon} from './FloatingIcon';

export default function Aspect(props) {

    const styles = {
        webDev: {
            heading: 'Web Development',
            subHeading: 'Wir erstellen moderne, responsive Websites für Ihren gelungenen Online-Auftritt',
            color: 'highlight',
            icon: 'mdi mdi-monitor-cellphone',
            description: 'Die Erstellung von responsiven und optisch ansprechenden Websites ist eine meiner Leidenschaften',
            entries: [
                'Singe Page Applications (SPAs)',
                'Responsive Design',
                'SEO-Optimierung',
                'Logos & Corporate Design'
            ],
            tags: ['React', 'Vue js', 'angular', 'javascript', 'typescript']
        },
        backend: {
            heading: 'Backend Applications',
            subHeading: 'Wir entwickeln leistungsstarke und robuste Services für Ihr Business',
            color: 'highlight',
            //icon: 'mdi mdi-application-braces-outline',
            icon: 'mdi mdi-server',
            entries: [
                'Domain-Driven Design',
                'Specification-First-API-Design',
                'Exakte Prozessabbildung in Software',
                'Integration von Drittsystemen',
            ],
            tags: [
                'Spring', 'JAX-RS', 'Vert.x', 'micronaut', 'GraalVM', 'Spring Native', 'swagger', 'openAPI', 'JPA', 'flyway', 'maven', 'gradle', 'mariadb', 'mySQL', 'oracleDB', 'postgreSQL'
            ]
        },
        apps: {
            heading: 'Mobile Apps',
            subHeading: 'Wir bringen Ihre Anwendung auf das Smartphone Ihrer Kunden',
            color: 'highlight',
            icon: 'mdi mdi-cellphone',
            entries: [
                'Vollständiger Entwicklungszyklus:',
                'Prototyping, Entwicklung & Release',
                'Platformübergreifend (iOS & Android)',
            ],
            tags: ['android', 'react-native', 'flutter', 'iOS', 'gradle', 'Unity', 'libGDX']
        },
        ai: {
            heading: 'Künstliche Intelligenz',
            subHeading: 'Wir analysieren, wo KI in Ihrem Unternehmen Mehrwert schafft',
            color: 'highlight',
            icon: 'mdi mdi-creation-outline',
            entries: [
                'Anwendung von KI-Modellen',
                'Integration von LLMs und GPTs',
                'Automatisierung von Prozessen',
            ],
            tags: ['LLMs', 'GPTs', 'Gen-AI', 'Stable Diffusion', 'TensorFlow', 'pytorch']
        },
        testing: {
            heading: 'Qualität durch Tests',
            subHeading: 'TDD',
            color: 'green',
            icon: 'mdi mdi-test-tube',
            description: 'Design und Umsetzung von Cross-Platform Apps',
            entries: [
                'Qualitätssicherung durch testgetriebene Entwicklung (TDD)',
                'Coverage durch Unit-, Component- und Integrationstests',
                'Abbildung von Business Requirements durch Behaviour-Driven Development (BDD)',
            ],
            tags: ['behave', 'cucumber', 'jUnit4/5', 'mockito', 'assertJ']
        },
        prototyping: {
            heading: 'Rapid Prototyping',
            subHeading: 'Sie haben eine Idee? Wir setzen sie um!',
            color: 'highlight',
            icon: 'mdi mdi-lightbulb-outline',
            entries: [
                'Technische Machbarkeitsbewertungen',
                'MVP Development',
                'UI/UX-Prototyping',
                'Visualisierung von Konzepten'
            ],
            tags: ['Extreme Programming']
        },
        design: {
            heading: 'UI/UX Design',
            color: 'highlight',
            icon: 'mdi mdi-palette',
            subHeading: 'Benutzerfreundliche und ansprechende Oberflächen für Ihre Anwendungen',
            entries: [
                'Konzeption und Design von UIs',
                'Nutzerzentrierte Gestaltung',
                'Prototyping und Testing',
            ],
            tags: ['Figma', 'Photoshop', 'Illustrator', 'InDesign', 'Sketch', 'Adobe XD']
        },
        contentCreation: {
            heading: 'Content Creation',
            subHeading: 'Wir unterstützen Sie bei der Erstellung von Inhalten für Ihre digitale Präsenz',
            color: 'highlight',
            icon: 'mdi mdi-movie-outline',
            entries: [
                'Audio- und Videoproduktion',
                'Texte und Grafiken',
                'Fotografie und Bildbearbeitung',
            ],
            tags: ['Content Marketing', 'Social Media', 'Analytics', 'TikTok', 'Instagram', 'YouTube']
        },
        refactoring: {
            heading: 'Flexibilität durch Clean Code',
            subHeading: 'Clean Code',
            color: 'highlight',
            icon: 'mdi mdi-monitor-shimmer highlight',
            entries: [
                'Softwaredesign aus fachlicher Betrachtung (Domain-Driven Design)',
                'Refactoring komplizierter, unflexibler Strukturen',
                'Clean Code & OOP Coachings',
            ],
            tags: ['OOP', 'Clean Code', 'Design Patterns', 'Domain-Driven-Design']
        },
        operations: {
            heading: 'Cloud & DevOps',
            subHeading: 'Ihre Cloud-Infrastruktur ist bei uns in guten Händen',
            color: 'highlight',
            icon: 'mdi mdi-cloud-outline highlight',
            description: 'Design und Umsetzung von Cross-Platform Apps',
            entries: [
                'Deployable Software durch Containerization',
                'Design skalierbarer Systeme',
                'Continuous Integration',
                'Operations, Dashboards & Monitoring',
            ],
            tags: ['docker', 'kubernetes', 'zookeeper', 'Containerization', 'logstash', 'kibana', 'graphana', 'datadog']
        },
    }

    const {tags, icon, heading, subHeading, color, entries} = styles[props.variant];
    const renderedTags = tags.map((t, i) => <span className={'tag ' + color} key={i}>{t}</span>);

    return (
        <div className={'aspect-container col-12 col-lg-4 content-holder-statement d-flex pe-lg-5 mb-5 mb-lg-0 py-3'}
             style={{flexDirection: 'column'}}>
            <center className={'consulting-heading aspect p-3 d-block h-100'}>
                <FloatingIcon color={color} icon={icon}/>
                <div className={'d-flex'}>
                    <div className={'flex-grow-1'}>
                        <div>
                            <Cycler values={tags}/>
                        </div>
                        <h3 style={{fontSize: '1.4rem', fontFamily: 'Lexend Deca'}}
                            className={'d-inline-block'}>{heading}</h3>
                    </div>
                </div>
                <div className={'mt-2'}>
                    <List color={color} entries={entries}/>
                </div>
            </center>
        </div>
    )
}