import React from 'react';
import Navigation from './navigation/Navigation';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import BlogOverview from './blog/BlogOverview';
import Entry from './blog/Entry';
import Footer from './footer/Footer';
import {BlogEntryList} from './blog/BlogEntryList';
import ContentHolder from './shared/ContentHolder';
import Newsletter from './blog/newsletter/Newsletter';
import {stopSession} from './blog/api/client';
import LandingPage from './LandingPage';
import ContactStandalone from './contact/ContactStandalone';
import Imprint from './imprint/Imprint';
import DataSecurity from './imprint/DataSecutity';

export default class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            currentComponent: undefined
        }
        window.onbeforeunload = function () {
            void stopSession();
        }
    }

    render() {
        const {currentComponent} = this.state;
        const updateCurrentComponent = (c) => this.setState({
            ...this.state,
            currentComponent: c
        });
        return (<>
            <Navigation currentComponent={currentComponent}/>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<LandingPage updateCurrentComponent={updateCurrentComponent}/>}/>
                    <Route exacct path={'/contact'}
                           element={<ContactStandalone updateCurrentComponent={updateCurrentComponent}/>}/>
                    <Route exact path={'/imprint'} element={<Imprint/>}/>
                    <Route exact path={'/data-security'} element={<DataSecurity/>}/>
                    <Route exact path={'/blog'}
                           element={<BlogOverview updateCurrentComponent={updateCurrentComponent}/>}/>
                    <Route exact path={'/blog/:id'} element={<Entry/>}/>
                    <Route exact path={'/blog/archive/:page'}
                           element={<ContentHolder>
                               <BlogEntryList/>
                               <div className={'pb-5'}/>
                           </ContentHolder>}/>
                    <Route exact path={'/newsletter/activate-subscription'} element={<Newsletter/>}></Route>
                </Routes>
            </BrowserRouter>
            <Footer/>
        </>);
    }
}