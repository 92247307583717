import React from 'react';
import FadeIn from 'react-fade-in';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import './Consulting.scss';

import Aspect from './Aspect';
import PolkaDots from './PolkaDots';
import SectionHeading from './SectionHeading';
import Hint from '../shared/Hint';
import ThreeLogos from '../shared/ThreeLogos';

export default class Consulting extends React.Component {

    render() {
        const {visible} = this.props;
        const aspects = ['backend', 'webDev', 'operations', 'apps', 'prototyping', 'design', 'ai', 'contentCreation'];
        const renderedAspects = aspects.map((aspect, key) => <Aspect key={key} variant={aspect}/>);

        const other = ['testing', 'refactoring']
        const otherAspects = other.map((aspect, key) => <Aspect key={key} variant={aspect}/>);
        return (
            <div id={'consulting'} style={{minHeight: '100vh'}} className={'pb-8 bg-near-black'}>
                <PolkaDots visible={visible} backgroundColor={'black'} opacity={0.25}/>
                <FadeIn transitionDuration={300} visible={visible}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <SectionHeading subHeading={'Was wir für Sie tun können'} heading={'Unsere Leistungen'}/>
                        </div>
                    </div>
                    <div className={'row hint-dark bg-transparent'}>
                        <div className={'col-12 col-lg-12'}>
                            <FadeIn visible={visible} delay={300}>
                                <ThreeLogos logos={['', 'rocket-launch-outline', '']}/>
                            </FadeIn>
                        </div>
                        <div className={'col-12 col-lg-12'}>
                            <Hint variant={'offerings'}/>
                        </div>
                    </div>
                    <div className={'container-fluid'}>
                        <div style={{margin: 'auto'}}
                             className={'container content-holder row d-none d-lg-flex g-0 px-3 justify-content-center'}>
                            {renderedAspects}
                        </div>
                        <div className={'content-holder d-lg-none row gx-0 px-lg-3'}>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                pagination={{clickable: true}}
                                scrollbar={{draggable: true}}
                                spaceBetween={1}
                                slidesPerView={1}
                                color={'red'}
                                loop
                                autoplay={{delay: 10000}}
                            >{
                                aspects.map((a, i) => <SwiperSlide key={i}><Aspect variant={a}/></SwiperSlide>)
                            }
                            </Swiper>
                        </div>
                    </div>

                    <div className={'container bg-effect text-white my-3 rounded-3'}>
                        <div className={'row'}>
                            <SectionHeading subHeading={'Vertrauen durch Qualität'} heading={'Unsere Grundsätze'}/>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12 col-lg-12'}>
                                <FadeIn visible={visible} delay={300}>
                                    <ThreeLogos variant={'text-white'} logos={['', 'check-decagram-outline', '']}/>
                                </FadeIn>
                            </div>
                            <div className={'col-12 col-lg-12'}>
                                <Hint variant={'quality'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'container-fluid'}>
                        <div style={{margin: 'auto'}}
                             className={'container content-holder row d-none d-lg-flex g-0 px-3 justify-content-center'}>
                            {otherAspects}
                        </div>
                        <div className={'content-holder d-lg-none row gx-0 px-lg-3'}>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                pagination={{clickable: true}}
                                scrollbar={{draggable: true}}
                                spaceBetween={1}
                                slidesPerView={1}
                                color={'red'}
                                loop
                                autoplay={{delay: 10000}}
                            >{
                                other.map((a, i) => <SwiperSlide key={i}><Aspect variant={a}/></SwiperSlide>)
                            }
                            </Swiper>
                        </div>
                    </div>

                </FadeIn>
            </div>
        );
    }
}