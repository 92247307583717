import React, {useEffect} from 'react';
import {ReactComponent as Cube} from '../assets/logo/cube.svg';

export const HeroCycler: React.FC<{ values: string[] }> = ({values}) => {

    const [index, setIndex] = React.useState(0);

    const nounHolder = React.useRef<HTMLSpanElement>(null);
    const verbHolder = React.useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const current = verbHolder.current;
            if (current) {
                current.classList.remove('show');
                setTimeout(() => {
                    current.classList.add('show');
                    setIndex((index + 1) % values.length);
                }, 330);
            }
        }, 5000)
        return () => clearInterval(interval);
    }, [index, values]);

    const noun = values[index].split(' ')[0];
    const verb = values[index].split(' ')[1];

    return (
        <div className={'border-anim bg-near-black'} style={{
            fontSize: '2.2rem',
            width: '500px',
            backdropFilter: 'blur(32px)',
            backgroundColor: 'rgba(0,0,0,0.33)',
            textAlign: 'center'
        }}>
            {noun}
            <span ref={verbHolder} className={'value-holder highlight ps-2 fw-light show'}>{verb}</span>
        </div>
    );
}
