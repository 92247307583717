import React from 'react';
import {BlogEntry} from '../models/models';
import './BlogPreview.scss';
import FadeIn from 'react-fade-in';

interface Props {
    entry: BlogEntry,
    index: number,
    colSize?: number
}

const BlogPreview: React.FC<Props> = (props) => {
    const {title, preview, author, coverImageURL, date, urlId, tags, likes} = props.entry;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const key = props.index;
    const {colSize = 6} = props;
    return (
        <div
            className={`col-sm-${colSize} col-lg-${colSize} card border-0 bg-transparent` + (colSize === 6 ? ' mb-5' : '')}>
            <div className={'card h-100 border-0 bg-transparent'}>
                <FadeIn>
                    <img onClick={() => navigateToBlogEntry(urlId)} src={coverImageURL} alt={'alt'}
                         className={'card-img-top'}/>
                </FadeIn>
                <FadeIn delay={500 + key * 100}>
                    <div className={'blog-preview card-body px-0 bg-transparent'}
                         onClick={() => navigateToBlogEntry(urlId)}>
                        <div>
                            <h3 className=" card-title py-2 m-0 blog-title">{title}</h3>
                            <div className={'pt-1 truncated-preview'}>{preview}</div>
                            <div className={'blog-metrics pt-1'}>
                                <span className={'blog-date'}>
                                    <span className={'mdi mdi-calendar-outline'}/>
                                    {new Date(date).toDateString()}
                                </span>
                                <span className={'px-2'}>•</span>
                                <span className={'mdi mdi-book-open-page-variant-outline'}/>5 min
                                <span className={'px-2'}>•</span>
                                <span className={'mdi mdi-heart-outline highlight'}/>{likes.toLocaleString('de-DE')}
                            </div>
                        </div>
                    </div>
                </FadeIn>

            </div>
        </div>
    )
}

function navigateToBlogEntry(id: string) {
    window.location.href = `/blog/${id}`;
}

export default BlogPreview;