import React, {useEffect} from 'react';

export const Cycler: React.FC<{ values: string[] }> = ({values}) => {

    const [index, setIndex] = React.useState(0);

    const valueHolderRef = React.useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const current = valueHolderRef.current;
            if (current) {
                current.classList.remove('show');
                setTimeout(() => {
                    current.classList.add('show');
                    setIndex((index + 1) % values.length);
                }, 330);
            }
        }, 3000)
        return () => clearInterval(interval);
    }, [index, values]);
    return <span ref={valueHolderRef} className={'value-holder pr-3 highlight'}>{values[index]}</span>
}
