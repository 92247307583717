import React from 'react';
import {ProgressBar} from 'react-bootstrap';

export default class AnimatedProgressbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            animatedValue: 0,
            threshold: props.threshold,
        };
        this.scrolling = false;
        let scrollTimeout = null;
        window.addEventListener('scroll', () => {
            window.clearTimeout(scrollTimeout);
            this.scrolling = true;
            scrollTimeout = setTimeout(() => this.scrolling = false, 66);
        }, false);
    }

    updateAnimation = () => {
        if (this.scrolling) {
            return;
        }
        const {animatedValue, threshold} = this.state;
        if (animatedValue < threshold) {
            this.setState({
                animatedValue: animatedValue >= threshold ? threshold : animatedValue + 1
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps.visible && this.props.visible) {
            const animationLength = 2000;
            const threshold = this.state.threshold;
            const stepSize = animationLength / threshold;
            this.animator = setInterval(this.updateAnimation.bind(this), stepSize);
        } else if (prevProps.visible && !this.props.visible) {
            this.setState({
                animatedValue: 0
            }, () => clearInterval(this.animator));
        }
    }

    componentWillUnmount() {
        clearInterval(this.animator);
    }

    render() {
        const {color, score} = this.props;
        return (
            <ProgressBar now={this.state.animatedValue} variant={color}/>
        );
    }
}
