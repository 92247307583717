import React from 'react';
import SectionHeading from '../consulting/SectionHeading';
import FadeIn from 'react-fade-in';
import List from '../consulting/List';

const DataSecurity: React.FC = () => {
    return (
        <FadeIn transitionDuration={800}>
            <div className="container-fluid text-muted px-lg-5" style={{overflowY: 'hidden'}}>
                <div className={'container'} style={{
                    marginTop: '160px',
                    textAlign: 'justify'
                }}>
                    <div className={'row'}>
                        <div className="text-white col-lg-8 col-sm-12">
                            <SectionHeading centered={false} heading={'Allgemeiner Hinweis und Pflichtinformationen'}
                                            subHeading={'DATENSCHUTZERKLÄRUNG'}/>
                            <h4 className={'highlight pb-3'}>Benennung der verantwortlichen Stelle</h4>
                            <div>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</div>
                            <div className={'pb-5 pt-3'}>
                                <div>sin2.io - Thanabalasingham GbR</div>
                                <div>Sinthujan Thanabalasingham</div>
                                <div>Reisewitzer Straße 22</div>
                                <div>01159 Dresden</div>
                            </div>
                            <div className={'pb-5'}>
                                Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke
                                und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o.
                                Ä.).
                            </div>
                            <h4 className={'highlight pb-3'}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
                            <div className={'pb-5'}>
                                Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung
                                möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für
                                den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
                                Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                            </div>
                            <h4 className={'highlight pb-3'}>Recht auf Beschwerde bei der zuständigen
                                Aufsichtsbehörde</h4>
                            <div className={'pb-5'}>
                                Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein
                                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
                                bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des
                                Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
                                stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
                                <a className={'d-block highlight'}
                                   href={'https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'}>
                                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                                </a>
                            </div>
                            <h4 className={'highlight pb-3'}>Recht auf Datenübertragbarkeit</h4>
                            <div className={'pb-5'}>
                                Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
                                Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen
                                zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die
                                direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
                                nur, soweit es technisch machbar ist.
                            </div>
                            <h4 className={'highlight pb-3'}>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h4>
                            <div className={'pb-5'}>
                                Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf
                                unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der
                                Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                                Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
                                Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum
                                aufgeführten Kontaktmöglichkeiten an uns wenden.
                            </div>
                            <h4 className={'highlight pb-3'}>SSL- bzw. TLS-Verschlüsselung</h4>
                            <div className={'pb-5'}>
                                Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an
                                uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung.
                                Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar.
                                Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
                                und am Schloss-Symbol in der Browserzeile.
                            </div>
                            <h4 className={'highlight pb-3'}>Server-Log-Dateien</h4>
                            <div className={'pb-5'}>
                                In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch
                                Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:

                                <div className={'py-3'}>
                                    <List entries={[
                                        'Besuchte Seite auf unserer Domain',
                                        'Datum und Uhrzeit der Serveranfrage',
                                        'Browsertyp und Browserversion',
                                        'Verwendetes Betriebssystem',
                                        'Referrer URL',
                                        'Hostname des zugreifenden Rechners',
                                        'IP-Adresse'
                                    ]}/>
                                </div>


                                Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage
                                der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                                zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                            </div>
                            <h4 className={'highlight pb-3'}>Kontaktformular</h4>
                            <div className={'pb-5'}>
                                Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten
                                gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen
                                bereitzustehen. <br/>
                                Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.
                                Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich
                                auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer
                                bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
                                formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}

export default DataSecurity;