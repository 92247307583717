import React from 'react';
import Hero from './hero/Hero';
import Consulting from './consulting/Consulting';
import ScrollHelper from './util/ScrollHelper';
import CookieConsent from 'react-cookie-consent';
import Team from './languages/Team';
import backgroundVideo from './assets/colors_4.mp4';

export default class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.components = ['hero', 'consulting', 'team'];
        this.state = {
            scrollPositionNearComponentEnd: false,
            components: this.components.map((c, _) => Object.assign({}, {name: c, start: 0, end: 0})),
            currentComponent: this.components[0]
        }
        window.addEventListener('resize', this.updateComponentHeights);
    }

    componentDidMount() {
        this.updateComponentHeights();
        ScrollHelper.registerScrollListener(this.updateCurrentComponent);
    }

    render() {
        const {scrollPosition} = this.state;
        return (
            <>
                <video className='background-video' autoPlay loop muted playbackRate="0.5">
                    <source src={backgroundVideo} type='video/mp4'/>
                </video>
                <CookieConsent buttonClasses='button-main-sm' disableButtonStyles>
                    Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Ihre
                    Website-Erfahrung zu optimieren und Ihnen einen persönlich auf Sie zugeschnittenen Service
                    bereitstellen zu können, sowohl auf dieser Website als auch auf anderen Medienkanälen.
                </CookieConsent>
                <Hero scrollPosition={scrollPosition}/>
                <Consulting scrollPosition={scrollPosition} visible={this.shouldComponentBeVisible('consulting')}/>
                <Team visible={this.shouldComponentBeVisible('team')}/>
            </>
        );
    }

    updateComponentHeights = () => {
        let components = Object.assign([], this.state.components);
        for (let i = 0; i < components.length; i++) {
            let component = components[i];
            const start = i === 0 ? 0 : components[i - 1].end;
            const height = document.getElementById(component.name).clientHeight;
            component.start = start;
            component.height = height;
            component.end = start + height;
        }
        this.setState({...this.state, components}, () => console.log(this.state));
    }

    updateCurrentComponent = (scrollPosition) => {
        let accumulatedScroll = 0;
        const navigation = document.getElementById('navigation');
        if (!navigation) {
            return;
        }
        const navigationHeight = navigation.clientHeight;
        const {currentComponent, components} = this.state;
        for (let component of components) {
            accumulatedScroll += component.height;
            if (accumulatedScroll >= scrollPosition + navigationHeight / 2.0) {
                let scrollPositionNearComponentEnd = component.end - scrollPosition < window.innerHeight * 0.9;
                if (currentComponent !== component) {
                    this.setState({
                        ...this.state,
                        currentComponent: component.name,
                        scrollPositionNearComponentEnd
                    }, () => this.props.updateCurrentComponent(component.name));
                    return;
                }
            }
        }

    }

    shouldComponentBeVisible = (component) => {
        const componentIndex = this.components.findIndex(c => c === component);
        const previousComponent = this.components[Math.max(componentIndex - 1, 0)];
        const {currentComponent, scrollPositionNearComponentEnd} = this.state;
        return (currentComponent === previousComponent && scrollPositionNearComponentEnd) || currentComponent === component;
    }
}