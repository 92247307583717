import React from 'react'
import {Helmet} from 'react-helmet';

interface Props {
    metadata: MetaData
}

interface MetaData {
    type: string,
    title: string,
    description: string,
    url: string,
    image?: string
}

export const SEOHeader: React.FC<Props> = (props) => {
    const {type, title, description, url, image} = props.metadata;
    return (
        <Helmet>
            // twitter card
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@codingbratan"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            {image && <meta name="twitter:image" content={image}/>}
            <meta name="twitter:creator" content={'@codingbratan'}/>
            <meta name="twitter:domain" content="https://html.sammy-codes.com/images/large-profile.jpg"/>

            <meta property="og:site_name" content={'sin2.io - Ihr Software Dienstleister'}/>
            <meta property="og:type" content={type}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:url" content={url}/>
            {image && <meta property="og:image" content={image}/>}

            <meta name={'title'} content={title}/>
            <title>{title}</title>
            <meta name={'description'} content={description}/>
        </Helmet>
    )
}