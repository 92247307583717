import React from 'react';
import './Navigation.scss';
import {Container, Navbar} from 'react-bootstrap';
import {ReactComponent as Sin2Io} from '../assets/logo/logo.svg';
import ScrollHelper from '../util/ScrollHelper';

export default class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blur: false,
            expanded: false,
            scrollPosition: document.body.scrollTop || document.documentElement.scrollTop,
        }
        ScrollHelper.registerScrollListener((scrollPosition) => this.setState({scrollPosition}))
    }

    setExpanded = (expanded) => {
        this.setState({expanded});
    }

    setNavClose = () => {
        setTimeout(() => this.setState({expanded: false}), 200);
    }

    render() {
        const {currentComponent} = this.props;
        let variant = ['team'].includes(currentComponent) ? 'light' : 'dark';
        //TODO: fix navigation for rest of page
        //variant = 'dark';
        const lightOrDark = `synexion-${variant}`;
        const navLightOrDark = 'nav-link nav-' + lightOrDark;
        const {scrollPosition, expanded} = this.state;
        let navStyle = {};
        let blurClass = expanded ? 'blur' : 'blur';
        if (variant === 'dark') {
            navStyle.backgroundColor = 'rgba(1,3,5,' + Math.min(scrollPosition / 400.0, 0.8) + ')';
        }
        if (variant === 'light') {
            navStyle.backgroundColor = 'rgba(255,255,255,0.7)';
        }
        return (
            <div id={'navigation'} className={blurClass + ' position-fixed pt-3 pb-2 w-100 px-lg-5'} style={navStyle}>
                <Navbar
                    id={'navigation'}
                    variant={variant}
                    expand="lg"
                    bg='transparent'
                    onToggle={this.setExpanded}
                    expanded={expanded}
                >
                    <Container fluid>
                        <div className={'col-lg-3 ml-auto'}>
                            <Navbar.Brand href="/">
                                <Sin2Io className={lightOrDark}/>
                            </Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse className="col-lg-5 offset-lg-4 me-auto" id="basic-navbar-nav">
                            <ul onClick={this.setNavClose}
                                className="navbar-nav mb-2 mb-lg-0 col-lg-5 px-lg-4 flex-lg-grow-1">
                                <li className="nav-item">
                                    <a className={navLightOrDark} href="/#consulting">Leistungen</a>
                                </li>
                                <li className="nav-item">
                                    <a className={navLightOrDark} href="/#team">Team</a>
                                </li>
                                <li className="nav-item">
                                    <a className={navLightOrDark} href="/#consulting">Referenzen</a>
                                </li>
                                <li className="nav-item">
                                    <a className={navLightOrDark} href="/blog">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <a className={navLightOrDark + ' bt-sm-primary'} href="/contact">Anfrage</a>
                                </li>
                                <li className="nav-item dropdown ms-auto">
                                    <a className={'nav-link dropdown-toggle ' + navLightOrDark}
                                       href="#lang_de"
                                       id="navbarDropdown"
                                       role="button"
                                       data-bs-toggle="dropdown" aria-expanded="false">🇩🇪</a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-dark"
                                        aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className={'dropdown-item ' + navLightOrDark} href="#lang_en">🇬🇧EN</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}