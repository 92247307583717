import {ReactComponent as Diamond} from '../assets/logo/diamond.svg';
import React from 'react';

export default function List(props) {
    const colors = {
        'highlight': '#ff0059',
        'green': '#00E676',
        'blue': '#44a1ff',
    }
    const {entries, color} = props;
    const renderedEntries = (entries ? entries : []).map((e, key) =>
        <li key={key} className={'pe-lg-0 pe-3 d-flex'}>
            <div className={'flex-grow-1'}>
                {e}
            </div>
        </li>
    );
    return (
        <ul className={'m-0 p-0 opacity-50'}>
            {renderedEntries}
        </ul>
    )
}