import React from 'react';
import SectionHeading from '../consulting/SectionHeading';
import PolkaDots from '../consulting/PolkaDots';
import FadeIn from 'react-fade-in';
import './Languages.scss';
import programmingLanguages from './programming_languages.json';
import operatingSystems from './operating_systems.json';
import languages from './languages.json';
import AnimatedProgressbar from './AnimatedProgressbar';
import {ReactComponent as Rhombus} from '../assets/logo/rhombus.svg';

import colors from '../colors';
import Hint from '../shared/Hint';
import Contact from '../contact/Contact';
import {TeamMembers} from './TeamMembers';
import ThreeLogos from '../shared/ThreeLogos';

export default class Team extends React.Component {

    render() {
        const {visible} = this.props;
        const programmingBars = this.buildProgressBars(programmingLanguages, visible);
        const osBars = this.buildProgressBars(operatingSystems, visible);
        const languageBars = this.buildProgressBars(languages, visible);
        const web = ['React', 'Vue js', 'angular', 'bootstrap', 'CSS/SCSS', 'node', 'express', 'npm/yarn'];
        const mobile = ['android', 'react-native', 'flutter', 'iOS', 'gradle', 'Unity', 'libGDX'];
        const backend = ['Spring', 'JAX-RS', 'Vert.x', 'micronaut', 'GraalVM', 'Spring Native', 'swagger', 'openAPI', 'JPA', 'flyway', 'maven', 'gradle', 'mariadb', 'mySQL 5.7', 'oracleDB', 'postgreSQL'];

        const renderedWeb = web.map((t, i) => <span className={'tag highlight filled'} key={i}>{t}</span>);
        const renderedMobile = mobile.map((t, i) => <span className={'tag blue filled'} key={i}>{t}</span>);
        const renderedBackend = backend.map((t, i) => <span className={'tag green filled'} key={i}>{t}</span>);

        return (
            <div id={'team'} className={'row bg-white'}>
                <FadeIn transitionDuration={300} visible={visible}>
                    <div className={'pb-5'}>
                        <PolkaDots color={'-red'} backgroundColor={'white'} visible={visible}/>
                        <div className={'container bg-white'}>
                            <SectionHeading subHeading={'Das sind Wir'} heading={'Unser Team'}/>
                        </div>
                        <div className={'row bg-white'}>
                            <div className={'col-12 col-lg-12'}>
                                <FadeIn visible={visible} delay={300}>
                                    <ThreeLogos logos={['', 'brain', '']}/>
                                </FadeIn>
                            </div>
                            <div className={'col-12 col-lg-12'}>
                                <Hint variant={'skills'}/>
                            </div>
                        </div>
                        <TeamMembers visible={visible}/>
                        <div className={'container text-black'}>
                            <Contact variant={'light'}/>
                        </div>
                    </div>
                </FadeIn>
            </div>
        );
    }

    buildProgressBars = (items, visible) => {
        return items.sort((l1, l2) => l2.score - l1.score).map(({name, score, color}, key) =>
            <>
                <div style={{opacity: 0.66}}>
                    <AnimatedProgressbar key={key} threshold={score} visible={visible} color={color}/>
                </div>
                <div style={{marginBottom: '1rem'}} className={'d-flex'}>
                    <div className={'flex-grow-1'} style={{fontSize: '1.0rem', paddingTop: '6px', fontWeight: 500}}>
                        <span>{name}<span className={score > 80 ? 'mdi mdi-shimmer ' + color : ''}/></span>
                    </div>
                    <div className={'flex-grow-2'} style={{opacity: '1', paddingTop: '4px'}}>
                        {[...Array.from(Array(10)).keys()].map((k, i) => <Rhombus key={i} fill={colors[color]}
                                                                                  stroke={colors[color]}
                                                                                  fill-opacity={(score > k * 10) ? 0.66 : 0.66 - (k * 10 - score) * 0.1}/>)}
                    </div>
                </div>
            </>
        );
    }
}