import React, {useEffect, useState} from 'react';

export const FloatingIcon: React.FC<{ icon: string, color: string }> = ({icon, color}) => {


    interface Particle {
        id: number;
        left: string;
    }

    const [particles, setParticles] = useState<Particle[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            createParticle();
        }, Math.random() * 2000 + 500);

        return () => clearInterval(interval);
    }, []);

    const createParticle = () => {
        const newParticle = {
            id: Math.random(),
            left: `${Math.min(15 + (Math.random() * 100), 85)}%`,
        };
        setParticles(prevParticles => [...prevParticles, newParticle]);

        setTimeout(() => {
            setParticles(prevParticles => prevParticles.filter(p => p.id !== newParticle.id));
        }, 2000);
    };


    return <div className={'consulting-icon gradient-text mt-3'}>
        <div className={`${icon} ${color}`}/>
        {particles.map(particle => (
            <span key={particle.id} className="particle" style={{left: particle.left}}></span>
        ))}
    </div>
}