import React from 'react';
import './Entry.scss';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {duotoneSea as codeStyle} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import {useParams} from 'react-router-dom';
import {BlogEntry} from '../models/models';
import {getBlogEntry, likeBlogEntry} from './api/client';
import Mock from './mock/mock';
import ContentHolder from '../shared/ContentHolder';

import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {BlogEntryList} from './BlogEntryList';
import {SEOHeader} from './seo/SEOHeader';

interface Props {
    id?: string
}

interface Like {
    urlId: string,
    liked: boolean
}

function readLike(id?: string) {
    if (!id) {
        return false;
    }
    const storedLike = localStorage.getItem(id);
    if (storedLike) {
        const parsed: Like = JSON.parse(storedLike);
        return parsed.liked;
    }
    return false;
}

const Entry: React.FC<Props> = (props) => {
    new Mock().init();
    const [entry, setEntry] = React.useState<BlogEntry>();
    let {id} = useParams() || props.id;
    if (!id) {
        id = props.id;
    }
    const [liked, setLiked] = React.useState(readLike(id));
    const [likes, setLikes] = React.useState(0);

    React.useEffect(() => {
        if (id) {
            getBlogEntry(id).then((result) => {
                setEntry(result);
                setLikes(result.likes);
            });
        }
    }, [id]);


    React.useEffect(() => {
//        document.title = `${entry?.title} - sin2.io`;
        const metaElement = document.createElement('meta');
        metaElement.name = 'description'
        metaElement.content = entry?.preview ?? 'Developer Blog'
    }, [entry]);


    if (entry) {
        const {title, preview, tags, author, authorImageURL, content, coverImageURL, date, urlId} = entry;
        return (
            <ContentHolder>
                <SEOHeader metadata={{
                    url: `https://sin2.io/blog/${urlId}`,
                    title: `${title} | sin2.io`,
                    description: preview,
                    image: coverImageURL,
                    type: 'article'
                }}/>
                <div style={{paddingTop: '80px'}}>
                    <div className={'py-5'}>
                        <div className={'text-center'}>
                            <h3 className=" card-title m-0 blog-title">{title}</h3>
                            <div className={'blog-metrics pt-1'}>
                                <span className={'blog-date'}>
                                    <span className={'mdi mdi-calendar-outline'}/>
                                    {new Date(date).toDateString()}
                                </span>
                                <span className={'px-2'}>•</span>
                                <span className={'mdi mdi-book-open-page-variant-outline'}/>5 min
                                <span className={'px-2'}>•</span>
                                <span className={'mdi mdi-heart-outline highlight'}/>{likes.toLocaleString('de-DE')}
                                <div>
                                    <img src={authorImageURL} style={{
                                        borderRadius: '100%',
                                        width: '75px',
                                        height: '75px',
                                        margin: '0px auto',
                                    }} alt={'dude'}/>
                                    <div className={'mdi mdi-user flex-grow-1'}>{author}</div>
                                </div>
                            </div>
                        </div>

                        <img src={coverImageURL} alt={'alt'} className={'card-img'}/>
                    </div>
                    <div className={'blog-entry'}>
                        <ReactMarkdown remarkPlugins={[gfm]} children={content}
                                       components={{
                                           code({node, inline, className, children, ...props}) {
                                               const match = /language-(\w+)/.exec(className || '');
                                               return !inline && match ? (
                                                   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                   // @ts-ignore
                                                   <SyntaxHighlighter
                                                       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                       // @ts-ignore
                                                       style={codeStyle}
                                                       children={String(children).replace(/\n$/, '')}
                                                       language={match[1]}
                                                       {...props}
                                                   />
                                               ) : (
                                                   <code className={className} {...props}>
                                                       {children}
                                                   </code>
                                               );
                                           },
                                       }}
                        />
                    </div>
                    <div className={'py-5 d-flex justify-content-center align-items-center'}>
                        {id &&
                            <div id={'like-container'} className={'like-counter d-flex flex-column'}
                                 onClick={() => {
                                     likeBlogEntry(id || '').then((res) => {
                                         localStorage.setItem(res.urlId, JSON.stringify({
                                             urlId: res.urlId,
                                             liked: res.liked
                                         }));
                                         setLiked(res.liked);
                                         setLikes(likes + (res.liked ? 1 : -1));
                                     });
                                 }}>
                                <div className={'heart mdi mdi-heart' + (liked ? '' : '-outline')}/>
                                <div className={'likes'}>{likes}</div>
                            </div>
                        }
                        <Tooltip anchorId="like-container" content="You liked this blog post." events={['click']}/>
                    </div>
                    <div className={'text-align-center'}>
                        <h3 className={'pb-4'}>Other articles that might interest you</h3>
                    </div>
                    <BlogEntryList/>
                </div>
            </ContentHolder>
        );
    }
    return (<div>Loading</div>);
}

export default Entry;