import React, {useEffect, useRef} from 'react';
import * as THREE from 'three';

const ThreeJsComponent = ({width, height}) => {
    const containerRef = useRef();
    const scene = useRef();
    const camera = useRef();
    const renderer = useRef();
    const points = useRef([]);
    const pointObjects = useRef([]);
    const lines = useRef([]);
    const threshold = (150 / (800.0 * window.devicePixelRatio)) * width * window.devicePixelRatio;

    const randomVelocities = Array.from({length: 50}, () => ({x: Math.random(), y: Math.random()}));

    useEffect(() => {
        scene.current = new THREE.Scene();

        camera.current = new THREE.OrthographicCamera(
            width / -2, width / 2, height / 2, height / -2, 1, 2000
        );
        camera.current.position.z = 100;

        // Initialize renderer
        renderer.current = new THREE.WebGLRenderer({alpha: true});
        renderer.current.setSize(width, height);
        renderer.current.setClearColor(0xffffff, 0);
        renderer.current.setPixelRatio(window.devicePixelRatio);
        containerRef.current.appendChild(renderer.current.domElement);

        // Add points
        const geometry = new THREE.BufferGeometry();
        const vertices = [];
        for (let i = 0; i < 50; i++) {
            const point = new THREE.Vector3(Math.random() * width - width / 2, Math.random() * height - height / 2, 0);
            points.current.push(point);
            vertices.push(point.x, point.y, point.z);
        }
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        const material = new THREE.PointsMaterial({color: 0xff0059, size: 5 / window.devicePixelRatio});
        const pointCloud = new THREE.Points(geometry, material);
        pointObjects.current = pointCloud;
        scene.current.add(pointCloud);

        // Add lines if distance is below threshold
        const createLines = () => {
            lines.current.forEach(line => {
                scene.current.remove(line);
                line.material.dispose();
                line.geometry.dispose()
            });
            lines.current = [];

            for (let i = 0; i < points.current.length; i++) {
                for (let j = i + 1; j < points.current.length; j++) {
                    let d = points.current[i].distanceTo(points.current[j]);
                    if (d < threshold) {
                        const lineGeometry = new THREE.BufferGeometry().setFromPoints([points.current[i], points.current[j]]);
                        const lineMaterial = new THREE.LineBasicMaterial({
                            color: 0xff0059,
                            transparent: true,
                            opacity: Math.max(0, 1 - d / (threshold))
                        });
                        const line = new THREE.Line(lineGeometry, lineMaterial);
                        lines.current.push(line);
                        scene.current.add(line);
                    }
                }
            }
        };

        // Start animation loop
        const animate = () => {
            requestAnimationFrame(animate);

            // Update points positions
            const positions = pointObjects.current.geometry.attributes.position.array;
            for (let i = 0; i < points.current.length; i++) {
                points.current[i].x += randomVelocities[i].x;
                points.current[i].y += randomVelocities[i].y;

                // Boundary conditions
                if (points.current[i].x > width / 2 || points.current[i].x < -width / 2) randomVelocities[i].x *= -1;
                if (points.current[i].y > height / 2 || points.current[i].y < -height / 2) randomVelocities[i].y *= -1;

                positions[i * 3] = points.current[i].x;
                positions[i * 3 + 1] = points.current[i].y;
                positions[i * 3 + 2] = points.current[i].z;
            }
            pointObjects.current.geometry.attributes.position.needsUpdate = true;

            // Update lines
            createLines();

            // Render scene
            renderer.current.render(scene.current, camera.current);
        };

        animate();

        return () => {
            // Clean up
            containerRef.current.removeChild(renderer.current.domElement);
        };
    }, [width, height]);

    return <div ref={containerRef}/>;
};

export default ThreeJsComponent;
