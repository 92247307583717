import {BlogEntries, BlogEntry, SubscriptionActivationResponse} from '../../models/models';

const BASE_URL = process.env.REACT_APP_API_URL;

function getHeaders() {
    return {
        'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'x-session-id': sessionStorage.getItem('sessionId') || ''
    };
}

function processResponse(response: Response) {
    const sessionId = response.headers.get('x-session-id') ?? '';
    sessionStorage.setItem('sessionId', sessionId);
    return response.json();
}

export async function getBlogEntry(id: string): Promise<BlogEntry> {
    return fetch(`${BASE_URL}/blog/${id}`, {
        method: 'GET',
        headers: getHeaders()
    }).then(response => processResponse(response));
}

export async function getBlogEntries(page: number, limit?: number): Promise<BlogEntries> {
    const url = new URL(`${BASE_URL}/blog`);
    url.searchParams.append('page', String(page));
    if (limit) {
        url.searchParams.append('limit', String(limit));
    }
    return fetch(url.toString(), {
        method: 'GET',
        headers: getHeaders()
    }).then(response => processResponse(response))
        .catch((e: Error) => console.log(e.message));
}

export async function likeBlogEntry(urlId: string): Promise<any> {
    return fetch(`${BASE_URL}/blog/${urlId}/like`, {method: 'POST', headers: getHeaders()})
        .then(res => res.json())
        .catch((e: Error) => console.log(e.message));
}


export async function subscribeToNewsletter(email: string): Promise<any> {
    const body = JSON.stringify({email});
    return fetch(`${BASE_URL}/subscriptions`,
        {
            method: 'POST',
            headers: {...getHeaders(), 'Content-Type': 'application/json'},
            body: body
        })
        .then(res => res.json())
        .catch(error => console.log(error));
}

export async function activateNewsletterSubscription(hash: string): Promise<SubscriptionActivationResponse> {
    return fetch(`${BASE_URL}/subscriptions/${hash}/activate`, {
        method: 'POST',
        headers: getHeaders()
    })
        .then(res => res.json());
}

export async function stopSession(): Promise<any> {
    const session = sessionStorage.getItem('sessionId');
    return fetch(`${BASE_URL}/metrics/sessions/${session}/stop`, {method: 'POST'})
        .then(res => res.json())
        .catch(err => console.log(err));
}