import React from 'react';

interface Props {
    subHeading: string;
    heading: string;
    centered?: boolean;
}

export const SectionHeading: React.FC<Props> = ({heading, subHeading, centered = true}) => {

    return (
        <div style={{textAlign: centered ? 'center' : 'left'}}
             className={'row pb-4 mt-5 ' + (centered ? 'ms-auto me-auto' : '')}>
            <div className={'highlight mb-2 small'}>{subHeading.toUpperCase()}</div>
            <h2 style={{fontWeight: '300', fontFamily: 'Lexend Deca'}}>{heading}</h2>
        </div>
    )
}

export default SectionHeading;