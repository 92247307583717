export default class ScrollHelper {

    static registerScrollListener(callback) {
        window.addEventListener('scroll', () => ScrollHelper.listenToScroll(callback), {passive: true});
    }

    static listenToScroll = (callback) => {
        const documentElement = document.documentElement;
        const {scrollTop} = documentElement;
        const scrollPosition = document.body.scrollTop || scrollTop
        callback(scrollPosition);
    }
}