import React from 'react';
import './PolkaDots.scss';

type Props = {
    color?: string,
    visible: boolean,
    opacity?: number,
    backgroundColor?: string
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PolkaDots(props: Props) {
    let {color} = props;
    const {visible} = props;
    color = color ?? '';
    const opacity = props.opacity ?? 1;
    const backgroundColor = props.backgroundColor ?? 'transparent';
    return (
        <div className={'position-relative'}>
            <div className={'polka-bg' + color} style={{
                width: '100%',
                height: ' 50px',
                zIndex: '10000',
                opacity: opacity,
            }}>
            </div>
            <div className={'w-100 position-absolute ' + (visible ? 'polka-visible' : 'polka-hidden')}
                 style={{
                     height: '50px',
                     marginLeft: 0,
                     top: '0',
                     backgroundColor: backgroundColor
                 }}/>
        </div>
    )
}