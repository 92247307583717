import React from 'react';
import './TeamMembers.scss';
import FadeIn from 'react-fade-in';

interface Member {
    name: string;
    role: string;
    email: string;
    image: string;
}

interface Props {
    visible: boolean;
}

export const TeamMembers: React.FC<Props> = ({visible}) => {

    const members: Member[] = [
        {
            name: 'Sinthujan Thanabalasingham',
            role: 'Mitgründer & Full Stack Developer',
            email: 'sin2@sin2.io',
            image: 'sinthu'
        },
        {
            name: 'Janujan Thanabalasingham',
            role: 'Mitgründer & Full Stack Developer',
            email: 'janu@sin2.io',
            image: 'janu'
        },
        {
            name: 'Theresia Zimmermann',
            role: 'UI Designerin / UX Researcherin',
            email: 'tz@sin2.io',
            image: 'theresia'
        },
        {
            name: 'Sanja-Christelle Ignatius',
            role: 'Sales & Marketing',
            email: 'sci@sin2.io',
            image: 'sanja'
        }
    ]

    const renderedMembers = members.map((member, index) => {
            const {name, role, email, image} = member;
            return <div key={index} className={'col-lg-3 mb-sm-3'}>
                <div className={'p-4 member-container h-100'}
                     style={{borderRadius: '16px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
                    <FadeIn visible={visible} delay={index * 300} childClassName={'h-100'}>

                        <div className={`pb ${image} mb-3`}/>
                    </FadeIn>
                    <h4>{name}</h4>
                    <span className={'highlight'}>{role}</span>
                    <div className={'btn fw-light d-block text-start p-0'} onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `mailto:${email}`
                    }}><span className={'mdi mdi-email highlight pe-1'}/>{email}</div>
                </div>

            </div>
        }
    );

    return (
        <div className={'container'}>
            <div className={'row lg-5'}>
                {renderedMembers}
            </div>
        </div>
    );
}