import React from 'react';
import ReactDOM from 'react-dom';
import './styles/reset.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.scss';
import './styles/typography.scss';
import App from './App';
import CookieConsent, {OPTIONS} from 'react-cookie-consent';

import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

ReactDOM.render(
    <React.StrictMode>
        <CookieConsent
            overlay
            buttonText={'Accept'}
            location={OPTIONS.BOTTOM}
            declineButtonText={'Decline'}
            extraCookieOptions={{domain: 'sin2.io'}}
            enableDeclineButton
        >
            <h5>Notice</h5>
            We and selected third parties use cookies or similar technologies for technical purposes and, with your
            consent, for other purposes as specified in the cookie policy. Denying consent may make related features
            unavailable. Use the “Accept” button or close this notice to consent to the use of such technologies.
        </CookieConsent>
    </React.StrictMode>,
    document.getElementById('cookie-consent')
);
