import React from 'react';
import {BlogEntries} from '../models/models';
import BlogPreview from './BlogPreview';
import {useParams} from 'react-router-dom';
import {getBlogEntries} from './api/client';
import Mock from './mock/mock';

interface Props {
    limit?: number;
    showOnlyLatest?: boolean;
    entriesToRender?: BlogEntries
}

export const BlogEntryList: React.FC<Props> = (props) => {

    new Mock().init();
    const [entries, setEntries] = React.useState<BlogEntries>({
        items: [],
        totalCount: 0,
        resultCount: 0,
        previous: 0,
        next: 0
    });

    const page = parseInt(useParams().page ?? '0', 10);
    const {showOnlyLatest = false} = props;
    const limit = showOnlyLatest ? 1 : undefined;

    React.useEffect(() => {
        if (props.entriesToRender) {
            setEntries(props.entriesToRender);
        } else {
            getBlogEntries(page, limit).then((result: BlogEntries) => {
                if (result) setEntries(result)
            });
        }
    }, [showOnlyLatest, page, limit, props.entriesToRender]);


    const totalPages = Math.ceil(entries.totalCount / 8);
    const latestEntry = entries.items[0];
    return (
        <>
            <div className={'row'}>
                {(showOnlyLatest && latestEntry) &&
                    <BlogPreview index={0} key={latestEntry.id} entry={latestEntry} colSize={12}/>
                }
                {(!showOnlyLatest) && entries.items.map((entry, _key) =>
                    <BlogPreview key={entry.id} entry={entry} index={_key} colSize={6}/>
                )}
            </div>
            {page > 0 &&
                <div className={'text-center'}>
                    Page <br/>{page} / {totalPages}
                    <div>
                        {page > 1 &&
                            <a className={'highlight'} href={`/blog/archive/${entries.previous}`}>← Newer</a>
                        }
                        {
                            page > 1 && page !== totalPages &&
                            <span className={'px-3'}>|</span>
                        }
                        {page !== totalPages &&
                            <a className={'highlight'} href={`/blog/archive/${entries.next}`}>Older →</a>
                        }
                    </div>
                </div>
            }
            {page === 0 && !showOnlyLatest &&
                <div className={'text-center py-3'}>
                    <a className={'highlight'} href={'/blog/archive/1'}>See {entries.totalCount} more
                        interesting
                        articles</a>
                </div>
            }
        </>
    )
}