import React from 'react';
import './Socials.scss';

interface Props {
    variant?: 'hero' | 'footer';
}

export const Socials: React.FC<Props> = ({variant = 'hero'}) => {
    const socials = {
        linkedin: 'https://linkedin.com/in/sinthujan-thanabalasingam-35a646174',
        github: 'https://github.com/edisontrent1337',
        youtube: 'https://www.youtube.com/channel/UCKV6Vc-vw20GEZxVjqf9LFg/featured',
        twitter: 'https://twitter.com/codingbratan',
    }
    const renderedSocials = Object.entries(socials).map(([social, link], i) =>
        <a key={i} href={link}><span
            className={`mdi mdi-${social}` + (variant === 'footer' ? ' me-3 highlight footer-icon text-white' : '')}/></a>
    );
    return (

        <div className={variant === 'hero' ? 'socials-container' : 'text-start p0 m0'}>
            {renderedSocials}
        </div>
    )
}

export default Socials;