import React, {useCallback, useEffect, useState} from 'react';
import Contact from './Contact';
import FadeIn from 'react-fade-in';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backgroundVideo from '../assets/colors_4.mp4';
import ParticlesThree from '../particles/ParticlesThree';

interface Props {
    updateCurrentComponent: (component: string) => void;
}

export const ContactStandalone: React.FC<Props> = ({updateCurrentComponent}) => {
    const navigation = document.getElementById('navigation');
    const yOffset = navigation ? 80 : 0;

    const [viewPortHeight, setViewPortHeight] = useState(window.innerHeight);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const div = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        updateCurrentComponent('contact-standalone');
    }, []);

    return (
        <>
            <video className='background-video' autoPlay loop muted style={{left: '50px'}}>
                <source src={backgroundVideo} type='video/mp4'/>
            </video>
            <FadeIn transitionDuration={800}>
                <div className={'bg-transparent text-white'}>
                    <div className={'container-fluid'} style={{
                        paddingTop: '80px',
                        height: `calc(100% - ${yOffset}px)`,
                        maxHeight: `calc(100% - ${yOffset}px)`,
                    }}>
                        <div className={'row'}>
                            <div className={'col-lg-6 d-lg-block d-sm-none d-md-none d-none'}>
                                <div
                                    ref={div}
                                    id="particle-container"
                                    style={{
                                        height: `${viewPortHeight}px`,
                                        width: '100%',
                                    }}

                                >
                                    {width && <ParticlesThree width={width} height={height}/>}
                                </div>
                            </div>
                            <div className={'col-lg-1 d-lg-block d-none'}/>
                            <div className={'col-lg-4 text-white pt-lg-5'}
                                 style={{backgroundColor: 'rgba(0,0,0,0.85)', backdropFilter: 'blur(32px)'}}>
                                <Contact variant={'dark'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
        </>
    )
}

export default ContactStandalone;