import React from 'react';
import Mock from './mock/mock';
import ContentHolder from '../shared/ContentHolder';
import Hero from './hero/Hero';
import Socials from '../shared/Socials';
import {BlogEntryList} from './BlogEntryList';
import PolkaDots from '../consulting/PolkaDots';
import {getBlogEntries} from './api/client';
import {BlogEntries} from '../models/models';
import {SEOHeader} from './seo/SEOHeader';

const BlogOverview: React.FC = () => {

    new Mock().init();
    const [blogEntries, setBlogEntries] = React.useState<BlogEntries>({
        items: [],
        totalCount: 0,
        next: 0,
        previous: 0,
        resultCount: 0
    });
    React.useEffect(() => {
        getBlogEntries(0).then(setBlogEntries)
    }, []);

    return (
        <>
            <SEOHeader metadata={{
                title: 'sin2.io | Developer Tips and Programming Tutorials for Beginners',
                type: 'website',
                url: 'https://sin2.io',
                description: 'Welcome to sin2.io! Whether you\'re just starting out or looking to expand your skills: My articles cover a wide range of languages and frameworks, including HTML, CSS, JavaScript, Python, Java and more.' +
                    ' I understand that learning to code can be intimidating, that\'s why I try to make sure to present the information in a clear, easy-to-understand format. You\'ll learn with practical examples to help you apply the concepts to your own projects.' +
                    ' Join my newsletter and let\'s build something great together!',

            }}/>


            <ContentHolder>
                <div className={'py-5'}>
                    <BlogEntryList showOnlyLatest entriesToRender={blogEntries}/>
                </div>
            </ContentHolder>

            <div className={'bg-dark-background-color'}>
                <PolkaDots visible/>
            </div>

            <ContentHolder backgroundColor={'#FFF'}>
                <Hero/>
                <div className={'blog-hero-container text-center py-5'}>
                    <h4>... or find me on my socials:</h4>
                    <Socials/>
                </div>

            </ContentHolder>
            <div className={'bg-dark-background-color'}>
                <PolkaDots visible/>
                <ContentHolder>
                    <div className={'text-center py-5'}>
                        <h3 style={{fontStyle: 'normal', fontWeight: 'lighter'}}>
                            <span style={{fontSize: '1.4rem'}}>✨ </span>
                            Recent Articles
                            <span style={{fontSize: '1.4rem'}}> ✨</span>
                        </h3>

                    </div>
                    <BlogEntryList entriesToRender={blogEntries}/>
                </ContentHolder>
            </div>
        </>
    );
}

export default BlogOverview;