import React from 'react';

export default function ThreeLogos(props) {
    const [first, middle, end] = props.logos;
    let {variant} = props ?? 'gradient-text'
    if (!variant) variant = 'gradient-text';
    const baseClassName = 'mdi mdi-';
    return (
        <center style={{fontSize: '10rem', lineHeight: '0.9'}} className={`${variant}`}>
            <span className={`${variant}`}>
                <span style={{fontSize: '6.7rem'}} className={baseClassName + first}/>
                <div className={baseClassName + middle + ' rocket'}/>
                <span style={{fontSize: '4.5rem'}} className={baseClassName + end}/>
            </span>
        </center>
    );
}