import React from 'react';
import SectionHeading from '../consulting/SectionHeading';
import FadeIn from 'react-fade-in';

const Imprint: React.FC = () => {
    return (
        <FadeIn transitionDuration={800}>
            <div className="container-fluid px-lg-5" style={{overflowY: 'hidden'}}>
                <div className={'container'} style={{
                    marginTop: '160px',
                    textAlign: 'justify'
                }}>
                    <div className={'row'}>
                        <div className="text-white col-lg-8 col-sm-12">
                            <SectionHeading centered={false} heading={'Angaben gemäß § 5 TMG'}
                                            subHeading={'Impressum'}/>
                            <div className={'pb-5'}>
                                <div>sin2.io - Thanabalasingham GbR</div>
                                <div>Reisewitzer Straße 22</div>
                                <div>01159 Dresden</div>
                            </div>
                            <h4 className={'highlight pb-3'}>Kontakt</h4>
                            <div className={'pb-5'}>
                                <div>Telefon: +49 176 320 20 420</div>
                                <div>E-Mail: <a className={'highlight'} href="mailto:sin2@sin2.io">sin2@sin2.io</a>
                                </div>
                            </div>
                            <h4 className={'highlight pb-3'}>Haftung für Inhalte</h4>
                            <div className={'pb-5'}>
                                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                                nach
                                den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                                Diensteanbieter
                                jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                                überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                                nach
                                den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                                jedoch
                                erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                                entfernen.
                            </div>
                            <h4 className={'highlight pb-3'}>Haftung für Links</h4>
                            <div className={'pb-5'}>
                                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                                Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                                übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                                oder
                                Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                                Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                                verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                                entfernen.
                            </div>
                            <h4 className={'highlight pb-3'}>Urheberrecht</h4>
                            <div className={'pb-5'}>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                                dem
                                deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                                Zustimmung
                                des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für
                                den
                                privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
                                nicht
                                vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                                Bei
                                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                Footer

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}

export default Imprint;