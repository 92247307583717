import React, {useCallback, useEffect, useState} from 'react';
import FadeIn from 'react-fade-in';
import './Hero.scss';
import Contact from '../contact/Contact';
import PolkaDots from '../consulting/PolkaDots';
import Socials from '../shared/Socials';
import {HeroCycler} from '../consulting/HeroCycler';
import ParticlesThree from '../particles/ParticlesThree';

const Hero = () => {
    const navigation = document.getElementById('navigation');
    const yOffset = navigation ? 80 : 0;

    const [fadedIn, setFadedIn] = useState(false);
    const [contactVisible, setContactVisible] = useState(false);
    const [viewPortHeight, setViewPortHeight] = useState(window.innerHeight);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const div = useCallback(node => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
            setWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        setFadedIn(true);
    }, []);

    const toggleContactForm = useCallback(() => {
        setContactVisible(true);
    }, []);

    return (
        <FadeIn transitionDuration={1000} delay={1000}>
            <div id="hero">
                <div className="container-fluid px-lg-5" style={{overflowY: 'hidden'}}>
                    <div className={fadedIn ? 'my-card my-card-faded-in' : 'my-card'}>
                        <div
                            className="d-sm-flex flex-sm-column flex-md-column flex-lg-row"
                            style={{
                                marginTop: '80px',
                                height: `calc(100% - ${yOffset}px)`,
                                maxHeight: `calc(100% - ${yOffset}px)`,
                            }}
                        >
                            <div className="col-lg-7 col-12 position-relative d-flex">
                                <div
                                    ref={div}
                                    id="particle-container"
                                    style={{
                                        height: `${viewPortHeight - yOffset}px`,
                                        width: '100%',
                                    }}

                                >
                                    {(width > 0) && <ParticlesThree width={width} height={height}/>}
                                </div>
                                <div
                                    className="position-absolute fw-light"
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '1.5rem'
                                    }}
                                >
                                    <HeroCycler
                                        values={[
                                            'Komplexität beherrschen',
                                            'Synergien nutzen',
                                            'Prozesse optimieren',
                                            'Fortschritt gestalten',
                                            'Verbindungen schaffen'
                                        ]}
                                    />
                                </div>
                            </div>
                            <div
                                className={`main-heading px-0 flex-lg-grow-0 col-lg-5 row ${
                                    contactVisible ? 'd-flex' : 'd-none'
                                }`}

                            >
                                <FadeIn visible={contactVisible}>
                                    <Contact variant="dark" close={() => setContactVisible(false)}/>
                                </FadeIn>
                            </div>
                            <div
                                className={`main-heading flex-lg-grow-0 col-lg-5 row ${
                                    contactVisible ? 'd-none' : 'd-flex'
                                }`}
                                style={{
                                    height: `${viewPortHeight - yOffset}px`,
                                    marginTop: '0px'
                                }}
                            >
                                <div className="align-self-end">
                                    <span className="sub-heading highlight">Komplexität beherrschen</span>
                                    <span className="surname" style={{fontWeight: '500 !important'}}>
                    Wir wissen, worauf es ankommt
                  </span>
                                </div>
                                <div
                                    className="mt-4 debug text-muted"
                                    style={{
                                        fontSize: '1.15rem',
                                        textAlign: 'justify',
                                        lineHeight: '1.5',
                                        opacity: 0.7
                                    }}
                                >
                                    Vertrauen Sie auf unsere Expertise, maßgeschneiderte Lösungen zu entwickeln, die
                                    nicht nur Ihren aktuellen Anforderungen entsprechen, sondern auch zukunftssicher
                                    sind. Wir wissen, wie Komplexität beherrscht und aufgelöst werden kann.
                                </div>
                                <div className="align-self-start">
                                    <center>
                                        <a className="button-main d-block" href="#consulting">
                                            LEISTUNGEN
                                        </a>
                                        <a href={'/contact'} className="d-block button-main filled">
                                            Anfrage stellen
                                        </a>
                                    </center>
                                    <div className="my-4">
                                        <PolkaDots color="-red" visible opacity={0.5}/>
                                    </div>
                                    <div className="content-holder-statement bg-transparent">
                                        <Socials/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
};

export default Hero;
