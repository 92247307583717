import React from 'react';
import {useSearchParams} from 'react-router-dom';
import ContentHolder from '../../shared/ContentHolder';
import {activateNewsletterSubscription} from '../api/client';
import {SubscriptionState} from '../../models/models';
import Hero from '../hero/Hero';
import {BlogEntryList} from '../BlogEntryList';
import FadeIn from 'react-fade-in';


const Newsletter: React.FC = () => {
    const [searchParams, setSearchparams] = useSearchParams();
    const hash = searchParams.get('s');

    const [subscriptionState, setSubscriptionState] = React.useState(SubscriptionState.UNKNOWN);

    React.useEffect(() => {
        if (hash) {
            activateNewsletterSubscription(hash).then(res => {
                setSubscriptionState(res.status);
            });
        }
    }, [hash])

    return (
        <div className={'py-5 ' + (subscriptionState !== SubscriptionState.OK ? 'bg-white' : 'bg-transparent')}>
            <ContentHolder>
                {!hash &&
                    <div className={'bg-white'}>
                        <FadeIn>
                            <div className={'alert alert-danger'}>
                                This activation link is invalid.
                            </div>
                        </FadeIn>
                        <Hero/>
                    </div>
                }
                {(subscriptionState === SubscriptionState.OK) &&
                    <div>
                        <FadeIn>
                            <div className={'alert alert-success'}>
                                Your subscription was successfully activated.
                            </div>
                        </FadeIn>
                        <BlogEntryList/>
                    </div>
                }
                {(subscriptionState === SubscriptionState.LINK_INVALID) &&
                    <div className={'bg-white'}>
                        <FadeIn>
                            <div className={'alert alert-danger'}>
                                This activation link is invalid.
                            </div>
                        </FadeIn>
                        <Hero/>
                    </div>
                }
                {(subscriptionState === SubscriptionState.LINK_ALREADY_USED) &&
                    <div className={'bg-white'}>
                        <FadeIn>
                            <div className={'alert alert-danger'}>
                                This activation link was already used.
                            </div>
                        </FadeIn>
                        <Hero/>
                    </div>
                }
            </ContentHolder>
        </div>
    );
}

export default Newsletter;