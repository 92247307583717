import React from 'react';


interface Props {
    variant: string;
}

interface Link {
    title: string;
    url: string;
}

interface Section {
    key: string;
    title: string;
    links: Link[];
}

export const FooterSection: React.FC<Props> = ({variant}) => {

    const sections = {
        'company': {
            title: 'Company',
            links: [
                {title: 'Start', url: '/'},
                {title: 'Leistungen', url: '/#consulting'},
                {title: 'Referenzen', url: '/'},
                {title: 'Anfrage', url: '/contact'},
            ]
        },

        'culture': {
            title: 'Culture',
            links: [
                {title: 'Blog', url: '/blog'},
                {title: 'Team', url: '/#team'},
                {title: 'Tech Stack', url: '/'},
            ]
        },
        'contact': {
            title: 'Kontakt',
            links: [
                {title: 'sin2@sin2.io', url: 'mailto:sin2@sin2.io'},
                {title: '+49 176 320 20 420', url: 'tel:+4917632020420'},
            ]
        },
        'legal': {
            title: 'Legal',
            links: [
                {title: 'Impressum', url: '/imprint'},
                {title: 'Datenschutz', url: '/data-security'},
            ]
        }
    }
    const {title, links} = sections[variant as keyof typeof sections]
    const renderedLinks = links.map(link => (
        <li className="nav-item mb-2">
            <a href={link.url} className="nav-link px-0 pb-0 text-muted">{link.title}</a>
        </li>))

    return (
        <div className="col-6 col-md-2 mb-3">
            <div className={'pb-3 highlight'}>{title.toUpperCase()}</div>
            <ul className="nav flex-column">
                {renderedLinks}
            </ul>
        </div>
    )
}

export default FooterSection;