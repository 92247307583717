import React from 'react';
import './Hint.scss';

export default function Hint(props) {
    const variants = {
        offerings: {
            style: 'dark',
            text: <span>
Wir bieten Ihnen eine breite Palette an Dienstleistungen, die von der <span
                className={'highlight'}>Erstellung
                moderner Websites</span> über die <span className={'highlight'}>Entwicklung von mobilen Apps</span> bis hin zur <span
                className={'highlight'}>Integration von KI</span> in Ihre
                Geschäftsprozesse reicht.<br/><br/> Von der Konzeption bis zur Umsetzung – wir
                                        begleiten Sie auf jedem Schritt des Weges und sorgen für nachhaltigen Erfolg in
                                        der digitalen Welt.
            </span>
        },
        skills: {
            style: 'dark',
            text: <span>
                Unser Team besteht aus <span className={'highlight'}>studierten Informatikern</span>, die viele Jahre Erfahrung als Fullstack-Entwickler
        gesammelt haben. Unsere Experten bilden sich konstant weiter und gehen mit dem Trend.
            </span>
        },
        getInTouch: {
            style: 'light',
            text: <span>
                Interessieren Sie sich für unsere Leistungen, möchten Sie gern beraten werden oder haben Sie Fragen
                zu unseren Angeboten? Kontaktieren Sie uns:
            </span>
        },

        quality: {
            text: <span>
                Wir legen großen Wert auf die Qualität unserer Arbeit und sind erst zufrieden, wenn Sie es sind.
                Wir arbeiten testgetrieben und legen großen Wert auf sauberen Code und eine gute Dokumentation.
            </span>

        }
    }
    const {variant} = props;
    const {style, text} = variants[variant];
    return (

        <div className={'p-3 p-lg-5'}>
            <div className={'mx-auto'} style={{maxWidth: '700px'}}>
                {text}
            </div>
        </div>
    )
}