import React from 'react';
import './ContentHolder.scss';

import colors from '../colors_red_theme.module.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

type Props = {
    children?: React.ReactNode,
    backgroundColor?: string
}

export default function ContentHolder(props: Props) {

    return (
        <div className={'w-100'} style={{backgroundColor: props.backgroundColor ?? colors.darkBackgroundColor}}>
            <div className={'container-fluid px-lg-5 content-frame'}>
                {props.children}
            </div>
        </div>
    )
}